export default {
    user: [
        {
            title: "团队动态",
            actionCode: "dynamic",
        },
        {
            title: "查看成员",
            actionCode: "view",
        },
        {
            title: "退出团队",
            actionCode: "exit",
        },
    ],
    admin: [
        {
            title: "团队动态",
            actionCode: "dynamic",
        },
        {
            title: "重命名",
            actionCode: "edit",
        },
        {
            title: "查看成员",
            actionCode: "view",
        },
        {
            title: "添加成员",
            actionCode: "addMember",
        },
        {
            title: "退出团队",
            actionCode: "exit",
        },
    ],
    super: [
        {
            title: "团队动态",
            actionCode: "dynamic",
        },
        {
            title: "查看成员",
            actionCode: "view",
        },
        {
            title: "重命名",
            actionCode: "edit",
        },
        {
            title: "添加成员",
            actionCode: "addMember",
        },
        {
            title: "转让团队",
            actionCode: "transfer",
        },
        {
            title: "解散团队",
            actionCode: "dissolve",
        },
    ],
}