var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"company-team"},[_c('div',{staticClass:"company-team-wrap"},[_vm._m(0),_c('div',{staticClass:"company-team-table"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.teamList,"rowKey":(record) => record.id,"pagination":false,"customRow":_vm.customRow},scopedSlots:_vm._u([{key:"teamName",fn:function(text){return _c('span',{},[_c('h-icon',{staticClass:"teamIcon",attrs:{"iconName":"team"}}),_vm._v(" "+_vm._s(text)+" ")],1)}},{key:"createdBy",fn:function(text){return _c('span',{staticClass:"font12 gray-font"},[_vm._v(" "+_vm._s(text)+" ")])}},{key:"createTime",fn:function(text){return _c('span',{staticClass:"font12 gray-font"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(text))+" ")])}},{key:"updateTime",fn:function(text){return _c('span',{staticClass:"font12 gray-font"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(text))+" ")])}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-dropdown',[_c('a',[_c('a-icon',{attrs:{"type":"dash"}})],1),(!record.isStaff)?_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.actions[record.role]),function(item){return _c('a-menu-item',{key:item.actionCode,on:{"click":function($event){return _vm.handleAction(item.actionCode, record)}}},[_c('a',{class:{
                    danger:
                      item.title === '退出团队' ||
                      item.title === '解散团队',
                  }},[_vm._v(" "+_vm._s(item.title))])])}),1):_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.actionsForDefault[record.role]),function(item){return _c('a-menu-item',{key:item.actionCode,on:{"click":function($event){return _vm.handleAction(item.actionCode, record)}}},[_c('a',{class:{
                    danger:
                      item.title === '退出团队' ||
                      item.title === '解散团队',
                  }},[_vm._v(" "+_vm._s(item.title))])])}),1)],1)],1)}}])})],1)],1)]),(_vm.dynamicShow)?_c('TeamDynamic',{attrs:{"team":_vm.actionTeam},on:{"close":function($event){_vm.dynamicShow = false}}}):_vm._e(),(_vm.teamModalVisible)?_c('PlusTeamModal',{ref:"PlusTeamModal",attrs:{"visible":_vm.teamModalVisible,"type":_vm.modalType,"team":_vm.actionTeam},on:{"close":function($event){_vm.teamModalVisible = false},"plusOk":_vm.plusTeamOk,"editOk":_vm.editTeamOk,"addMember":_vm.handleAddMember}}):_vm._e(),(_vm.memberModalVisible)?_c('PlusMember',{ref:"PlusMember",attrs:{"visible":_vm.memberModalVisible,"type":_vm.memberModalType,"team":_vm.actionTeam},on:{"close":function($event){_vm.memberModalVisible = false},"plusOk":_vm.plusMemberOk,"sendMember":_vm.sendMemberList}}):_vm._e(),(_vm.transferTeamVisible)?_c('TransferTeamModal',{attrs:{"visible":_vm.transferTeamVisible,"team":_vm.actionTeam},on:{"close":function($event){_vm.transferTeamVisible = false},"Ok":_vm.transferTeamOk}}):_vm._e(),(_vm.dissolveTeamVisible)?_c('DissolveTeam',{ref:"DissolveTeam",attrs:{"mobel":_vm.mobel,"visible":_vm.dissolveTeamVisible,"team":_vm.actionTeam},on:{"close":function($event){_vm.dissolveTeamVisible = false},"Ok":_vm.dissolveTeamOk}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"company-team-header"},[_c('h2',{staticClass:"company-team-title font18 black-font"},[_vm._v("我的团队")]),_c('div',{staticClass:"company-team-header-action"})])
}]

export { render, staticRenderFns }