
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import PlusTeamModal from "./PlusTeamModal.vue";
import PlusMember from "./PlusMember.vue";
import TeamDynamic from "./TeamDynamic.vue";
import TransferTeamModal from "./TransferTeamModal.vue";
import DissolveTeam from "./DissolveTeam.vue";
import { namespace } from "vuex-class";
import actions from '../json/companyAction'
import actionsForDefault from '../json/companyActionDefault'



const UserModule = namespace("user");
const TeamModule = namespace("team");
const mobel = JSON.parse(localStorage.pro__Login_Userinfo)?.value?.phone

@Component({
  name: "CompanyTeamTable",
  components: {
    PlusTeamModal,
    PlusMember,
    TeamDynamic,
    TransferTeamModal,
    DissolveTeam,
  },
  filters: {
    //局部过滤器
  },
})
export default class CompanyTeamTable extends Vue {
  @UserModule.State("companyId") companyId!: string;
  @TeamModule.State("loading") loading!: boolean;
  @TeamModule.State("teamList") teamList!: any[];
  @TeamModule.Action("GET_TEAMLIST") getTeamList!: (
    companyId: string
  ) => void;
  @TeamModule.Mutation("SET_CURRENTTEAMAUTHORITY") setCurrentTeamAuthority!: (
    value: string
  ) => void;
  public columns: any[] = [
    {
      title: "名称",
      dataIndex: "teamName",
      align: "left",
      scopedSlots: { customRender: "teamName" },
    },
    {
      title: "创建者",
      dataIndex: "createdBy",
      align: "center",
      scopedSlots: { customRender: "createdBy" },
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      align: "center",
      scopedSlots: { customRender: "createTime" },
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      align: "center",
      scopedSlots: { customRender: "updateTime" },
    },
    {
      title: "操作",
      dataIndex: "",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];
  public teamModalVisible: boolean = false;

  public mobel: string=mobel;


  public showAddTeamFlag: boolean = false;
  public actions: any = actions;
  public actionsForDefault: any = actionsForDefault;

  public modalType: string = "plus";

  public memberModalType: string = "add";

  public actionTeam: any = {};

  public dynamicShow: boolean = false;

  public memberModalVisible: boolean = false;

  public transferTeamVisible: boolean = false;

  public dissolveTeamVisible: boolean = false;

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("PlusTeamModal")
  PlusTeamModalRef: any;
  @Ref("PlusMember")
  PlusMemberRef: any;

  // 添加团队
  handlePlus() {
    this.modalType = "plus";
    this.teamModalVisible = true;
  }

  customRow(record: any) {
    return {
      on: {
        // 点击行
        dblclick: () => {
          console.log(record);
          this.setCurrentTeamAuthority(record.role);
          this.$router.push({
            name: "VIEW_COMPANYTEAM_DOCUMENT",
            query: { teamId: record.id },
          });
        },
      },
    };
  }

  // 团队新建确认
  plusTeamOk(params: any) {
    this.$apis.companyTeamAddTeam(params).then((res: any) => {
      if (res.code === "200") {
        this.$message.success("新建团队成功");
        this.teamModalVisible = false;
        this.getTeamList(this.companyId);
      }
    }).catch((err: any) => {
      // this.teamModalVisible = false;
      //  this.$store.commit('team/SET_LOADING', false)
      this.PlusTeamModalRef.OkLoading = false
      console.log("🚀 新建团队失败:", err)

    })
  }

  // 团队编辑确认
  editTeamOk(params: any) {
    this.$apis.companyTeamRename(params).then((res: any) => {
      if (res.code === "200") {
        this.$message.success("编辑团队成功");
        this.teamModalVisible = false;
        this.getTeamList(this.companyId);
      }
    });
  }

  // 添加成员
  handleAddMember(team: any, memberList: any[]) {
    this.memberModalType = "edit";
    this.actionTeam = team;
    this.memberModalVisible = true;
    this.$nextTick(() => {
      this.PlusMemberRef.setMemberList(memberList);
    });
  }

  // 成员添加确认
  plusMemberOk(params: any) {
    this.$apis.addTteaMembermHttp(params).then((res: any) => {
      if (res.code === "200") {
        this.$message.success("操作成功");
        this.memberModalVisible = false;
        this.getTeamList(this.companyId);
      }
    });
  }

  // 团队转让确认
  transferTeamOk(params: any) {
    this.$apis.companyTeamTransfer(params).then((res: any) => {
      if (res.code === "200") {
        this.$message.success("转让团队成功");
        this.transferTeamVisible = false;
        this.getTeamList(this.companyId);
      }
    });
  }

  // 团队解散确认
  dissolveTeamOk(params: any) {
    this.$apis.companyTeamDisband(params).then((res: any) => {
      if (res.code === "200") {
        this.$message.success("解散团队成功");
        this.dissolveTeamVisible = false;
        this.getTeamList(this.companyId);
      }
    },()=>{
        (this.$refs.DissolveTeam as any).OkLoading = false
      });
  }

  // 发送成员列表到编辑页面
  sendMemberList(memberList: any[]) {
    this.PlusTeamModalRef.setMemberList(memberList);
    this.memberModalVisible = false;
  }

  // 企业操作
  handleAction(action: string, value: any) {
    console.log(action, value);
    switch (action) {
      case "dynamic":
        this.actionTeam = value;
        this.dynamicShow = true;
        break;
      case "view":
        this.modalType = "view";
        this.actionTeam = value;
        this.teamModalVisible = true;
        break;
      case "exit":
        const that = this;
        this.$confirm({
          title: "退出团队?",
          content:
            "退出团队后，你将不能在编辑和访问该团队内的文件，确定要退出吗？",
          okText: "是",
          okType: "danger",
          cancelText: "否",
          onOk() {
            that.$apis
              .companyTeamExit({ id: value.id })
              .then((res: any) => {
                if (res.code === "200") {
                  that.$message.success("退出团队成功");
                  that.getTeamList(that.companyId);
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
        break;
      case "edit":
        this.modalType = "edit";
        this.actionTeam = value;
        this.teamModalVisible = true;
        break;
      case "addMember":
        this.memberModalType = "add";
        this.actionTeam = value;
        this.memberModalVisible = true;
        break;
      case "transfer":
        this.actionTeam = value;
        this.transferTeamVisible = true;
        break;
      case "dissolve":
        this.actionTeam = value;
        this.dissolveTeamVisible = true;
        break;
      default:
        break;
    }
  }

  //创建前钩子函数
  created(): void {

    this.$apis.userRole().then((res: any) => {

      console.log('用户身份res', res);
      if (res && res.data && res.data.isAdmin) {
        this.showAddTeamFlag = true
      } else {
        this.showAddTeamFlag = false
      }




    }).catch(
      (err: any) => {
        this.showAddTeamFlag = false
        console.log("🚀 ~ file: CompanyTeamTable.vue:363 ~ CompanyTeamTable ~ created ~ err:", err)
      }
    )

    this.getTeamList(this.companyId);

    this.$nextTick(() => {
      console.log('获得的企业团队teamList', this.teamList);

    })

  }
}
