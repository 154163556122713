
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MemberList from "@/components/List/MemberList.vue";
import {getcode} from '@/apis/team'
@Component({
  name: "DissolveTeam",
  components: { MemberList },
  filters: {
    //局部过滤器
  },
})
export default class DissolveTeam extends Vue {
  public OkLoading: boolean = false;

  public inputValue: {
    code: string
  } = {
    code:''
  };
  public yzm:string = '获取验证码'
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false, type: Boolean })
  readonly visible!: boolean;
  @Prop()
  readonly team!: any;
  @Prop()
  readonly mobel!:string|number
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 点击关闭
  handleCancel() {
    this.$emit("close");
  }

  // 点击确认
  handleOk() {
    if (this.inputValue.code === "") {
        this.$message.warning("请输入验证码");
        return;
      } else {
        let params = {
          ...this.inputValue,
          teamId: this.team.id,
          mobile:this.mobel
        };
        this.OkLoading = true
        this.$emit('Ok',params)
      }
  }
  // 获取验证码
  async getCode() {
            const res:any= await getcode({ mobile:this.mobel})
            const code = res.code
            if (code == 200)
                this.$message.success('发送成功')
            this.countdown(60, 1000, (res:number) => {
                this.yzm = res < 10 ? `0${res}秒后重试` : `${res}秒后重试`
                if (res < 1) {
                    this.yzm = '获取验证码'
                }
            })
        }
      countdown(time:number, speed = 1000, callback:Function) {
            let times = time
            const str = setInterval(() => {
                times--
                if (times < 1) {
                    clearInterval(str)
                }


                callback(times)
                // return time--
            }, speed)

        }
  //创建前钩子函数
  created(): void {}
}
