
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MemberList from "@/components/List/MemberList.vue";

@Component({
  name: "TransferTeamModal",
  components: { MemberList },
  filters: {
    //局部过滤器
  },
})
export default class TransferTeamModal extends Vue {
  public OkLoading: boolean = false;

  public loading: boolean = false;

  public memberList: any[] = [];

  public selectMember: any = {};

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false, type: Boolean })
  readonly visible!: boolean;
  @Prop()
  readonly team!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 是否选中
  isSelect(value: any) {
    return this.selectMember.userId === value.userId;
  }

  // 选择成员
  handleSelect(value: any) {
    this.selectMember = value;
  }

  // 点击关闭
  handleCancel() {
    this.$emit("close");
  }

  // 点击确认
  handleOk() {
    if (this.selectMember.userId) {
      let params = {
        nickname: this.selectMember.nickname,
        teamId: this.team.id,
        userId: this.selectMember.userId,
      };
      this.$emit("Ok", params);
    } else {
      this.$message.warning("请选择需要转让的成员");
    }
  }

  // 获取团队成员
  queryTeamDetail() {
    this.loading = true;
    this.$apis
      .companyTeamQueryMember({ id: this.team.id })
      .then((res: any) => {
        if (res.code === "200") {
          this.memberList = res.data;
          console.log("🚀 ~获取团队成员 this.memberList:", this.memberList)
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  //创建前钩子函数
  created(): void {
    this.queryTeamDetail();
  }
}
