
import Vue from "vue";
// @ts-ignore
import selectMemberOrDep from "@/components/selectMemberOrDep";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MemberList from "@/components/List/MemberList.vue";
import { namespace } from "vuex-class";
const UserModule = namespace("user");

@Component({
  name: "PlusTeamModal",
  components: {
    MemberList,
    selectMemberOrDep,
  },
  filters: {
    //局部过滤器
  },
})
export default class PlusTeamModal extends Vue {
  @UserModule.State("userId") userId!: string;
  @UserModule.State("name") name!: string;
  @UserModule.State("email") email!: string;
  @UserModule.State("phone") phone!: string;
  @UserModule.State("companyId") companyId!: string;

  public selectType: any = 1;
  public checkedKeysList: any = [];
  public model: any = {};
  public disabledChangeTeamNameFlag: any = true;

  public checkList: any = []; // 选择到右侧待加入团队的人员列表
  public checkedKeys: any = []; // 树节点绑定的选择的key值
  public tenantName: any = ""; // 企业名称
  public total: number = 99;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public title: string = "";
  // 弹出框显示类型
  public modalType: string = "teamModal";
  // 确认按钮加载
  public OkLoading: boolean = false;
  // 团队名称
  public teamName: string = "";
  // 当前团队选中的成员
  public memberList: any[] = [];

  // 当前团队选中的成员VVVV-副本，留着做前端模糊搜索匹配过滤用
  public allMembers: any[] = [];
  // 成员权限
  public memberDropdown: any = [
    // {
    //   title: "超级管理员",
    //   value: "super",
    //   disabled: true,
    // },
    {
      title: "管理员",
      value: "admin",
    },
    {
      title: "普通成员",
      value: "user",
    },
  ];

  public treeData: any = [];

  /* 成员添加 */
  // 成员搜索
  public searchMember: string = "";
  public searchResultShow: boolean = false;
  public searchMemberList: any[] = [];
  // 主要的成员显示
  public current: string[] = ["contact"];
  // 最近联系人列表
  public recentMemberList: any[] = [];
  // 联系人列表
  public contactMemberList: any[] = [];
  public loading: boolean = false;
  public selectMember: any[] = [];
  public inputValue: any = "";

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false, type: Boolean })
  readonly visible!: boolean;
  @Prop({ required: true, type: String })
  readonly type!: string;
  @Prop()
  readonly team!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  @Watch("checkedKeys")
  onCheckedKeysChange(newVal: any[]) {
    // 在这里处理checkedKeys属性的变化
    console.log("checkedKeys changed", newVal);
    const allChildNodes: any[] = [];
    // this.checkedKeys = allChildNodes.map(obj => obj.key);
    this.getCheckedChildNodes(this.treeData, newVal, allChildNodes);
    this.checkList = allChildNodes;
    // 如果存在搜索结果在左侧，需要同步更新这里的数据
    this.selectMember = this.selectMember.filter((member) =>
      newVal.includes(member.userId)
    );
  }


  @Watch("inputValue", { immediate: true })
     inputValueChange(newVal:any) {
      if(!newVal.length) {
        this.searchTeamMember();
      }
  }

  handleClear() {
    this.checkList = [];
    this.checkedKeys = [];
  }

  addManager(userList: any, rows: any[]) {
    this.model.userIds = userList;

    this.checkedKeysList = userList;

    this.model.users = rows.map((p) => {
      return {
        userId: p.key,
        nickname: p.title,
        id: p.key,
        role: "user",
        avatar: p.avatar,
        email: p.email,
        icon: p.icon,
      };
    });

    console.log("---------this.model", this.model.users);

    // let tempList = this.checkList.map((obj: { title: any; key: any; }) => {
    //     return {
    //       nickname: obj.title,
    //       id: obj.key,
    //       userId: obj.key,
    //       role: 'user'
    //     };
    //   });
    // const merge: any[] = this.memberList.concat(this.selectMember).concat(tempList);
    const merge: any[] = this.memberList.concat(this.model.users);
    // 去重
    this.memberList = merge.reduce((total, value) => {
      if (total.findIndex((i: any) => i.userId === value.userId) === -1) {
        total.push(value);
      }
      return total;
    }, []);

    // 添加成员同步处理人员信息副本
    this.allMembers = JSON.parse(JSON.stringify(this.memberList));

    this.modalType = "teamModal";
    this.setTitle();

    console.log("添加了成员xxxxxxxxxxxxxxxxxxxxx");
  }



  searchTeamMember() {
    console.log('123----');
    // console.log("-----this.allMembers--------", this.allMembers);
    const searchValue = this.inputValue.toLowerCase();
      if (!searchValue) {
        this.memberList = this.allMembers;
      } else {
        this.memberList = this.allMembers.filter((item) => {
          const nickname = item.nickname?.toLowerCase() || '';
          const email = item.email?.toLowerCase() || '';
          return nickname.includes(searchValue) || email.includes(searchValue);
        });
      }
      console.log("-----searchTeamMembers", this.memberList);
  }


  //  添加成员页面返回
  handleBack() {
    // alert('999')
    // this.disabledChangeTeamNameFlag=false
    this.modalType = "teamModal";
    this.setTitle();
  }

  deleteItem(item: any) {
    // 取消勾选对应树形结构中的数据
    const index = this.checkedKeys.indexOf(item.key);
    if (index > -1) {
      this.checkedKeys.splice(index, 1); // 从 checkedKeys 数组中移除指定的 key 值
    }

    // 删除自己右侧列表中选中的数据

    const indexSelf = this.checkList.findIndex(
      (obj: { key: any }) => obj.key == item.key
    );
    console.log("indexSelf", indexSelf);

    if (indexSelf > -1) {
      this.checkList.splice(indexSelf, 1); // 从 checkList 数组中移除指定的对象
    }
  }

  handleTreeChange(checkedKeys: any, { checkedNodes }: any) {
    const allChildNodes: any[] = [];
    this.getCheckedChildNodes(this.treeData, checkedKeys, allChildNodes);
    this.checkedKeys = allChildNodes.map((obj) => obj.key);
    console.log("过滤父节点的节点信息:", this.checkedKeys);
    //  console.log("子节点的 key 数组:", keyArray);
    //  this.checkedKeys = this.keyArray,
    this.checkList = allChildNodes;
  }

  getCheckedChildNodes(nodes: any, checkedKeys: any, result: any[]) {
    for (const node of nodes) {
      if (checkedKeys.includes(node.key)) {
        if (!node.children || node.children.length === 0) {
          if (!result.some((item: { key: any }) => item.key === node.key)) {
            result.unshift(node);
          }
        }
      }
      if (node.children && node.children.length > 0) {
        this.getCheckedChildNodes(node.children, checkedKeys, result);
      }
    }
  }

  //  设置标题
  setTitle() {
    if (this.type === "view") {
      this.title = "团队信息";
    } else if (this.type === "edit") {
      this.title = "重命名";
      // this.deleteMemberFlag=true
    } else {
      this.title = "新建团队";
      this.disabledChangeTeamNameFlag = false;
    }
  }

  // 点击添加成员
  addMember() {
    if (this.type === "edit") {
      this.$emit("addMember", this.team, this.memberList);
    } else {
      // this.modalType = "memberModal";
      this.title = "添加成员";
      this.searchResultShow = false;
      this.current = ["contact"];
      this.searchMember = "";
      this.selectMember = [];
      // const filteredArray = this.memberList.filter(obj => obj.role !== 'super');
      this.checkedKeysList = this.memberList.map((obj) => obj.userId);
      // @ts-ignore
      this.$refs.selectMemberOrDep.show();

      // this.queryContactMember();
    }
  }

  // 设置成员列表
  setMemberList(memberList: any[]) {
    this.memberList = memberList;
  }

  // 成员搜索
  handleSearch() {
    this.searchContactMember(this.searchMember);
  }
  // 搜索成员输入框清空显示常规成员列表
  searchInputChange() {
    if (this.searchMember === "") this.searchResultShow = false;
  }

  // 是否选中
  isSelect(value: any): number {
    let index = this.selectMember.findIndex((i) => i.userId === value.userId);
    return index;
  }

  // 搜索联系人
  searchContactMember(keyword: string | undefined) {
    this.$apis
      .searchContact(keyword)
      .then((res: any) => {
        console.log("搜索联系人res", res);
        let filteredArray;
        if (res.result) {
          filteredArray = res.result.filter(
            (obj: { type: string }) => obj.type == "0"
          );
        }
        if (
          res.code == 200 &&
          res.result &&
          filteredArray &&
          filteredArray[0]
        ) {
          this.searchResultShow = true;
          let tempList = filteredArray[0].members;
          this.searchMemberList = tempList.map(
            (obj: { id: any; name: any }) => {
              return {
                userId: obj.id,
                role: "",
                nickname: obj.name,
              };
              // avatarImage: '',
              // loginId: '',
            }
          );
          // 如果右侧已经存在有选中人员列表
          if (this.checkList) {
            let checkListTemp = this.checkList.map(
              (obj: { id: any; title: any }) => {
                return {
                  userId: obj.id,
                  role: "",
                  nickname: obj.title,
                };
                // avatarImage: '',
                // loginId: '',
              }
            );
            const mergedSet = new Set([
              ...checkListTemp,
              ...this.searchMemberList,
            ]);
            // 转换回数组
            const mergedArray = Array.from(mergedSet);
            this.searchMemberList = mergedArray;
            this.selectMember = this.searchMemberList.filter((member) =>
              this.checkedKeys.includes(member.userId)
            );
          }
        }
      })
      .catch((err: any) => {});
  }

  // 选择/取消成员
  handleSelect(value: any) {
    console.log("value-----", value);

    // let index = this.isSelect(value);
    // if (index === -1) {
    //   this.selectMember.push({
    //     ...value,
    //     role: "user",
    //   });
    //   if (!this.checkedKeys.includes(value.userId)) {
    //     this.checkedKeys.unshift(value.userId);
    //   }

    // } else {
    //   this.selectMember.splice(index, 1);
    //   const indexs = this.checkedKeys.indexOf(value.userId);
    //   if (indexs !== -1) {
    //     this.checkedKeys.splice(indexs, 1);
    //   }
    // }
  }

  processTreeData(data: any[]) {
    return data.map((item) => {
      const newNode = { ...item };
      if (item.userList && item.userList.length > 0) {
        newNode.children = newNode.children || [];
        newNode.children.unshift(
          ...item.userList.map((user: { id: any; realname: any }) => ({
            id: user.id,
            key: user.id,
            title: user.realname,
            children: [],
          }))
        );
      }
      if (newNode.children && newNode.children.length > 0) {
        newNode.children = this.processTreeData(newNode.children);
      }
      return newNode;
    });
  }

  // 切换最近/联系人
  menuChange(value: any) {
    this.queryContactMember();
  }

  // 分配成员权限
  selectPrivileges(member: any, type: string) {
    console.log(member, type);

    let index = this.memberList.findIndex(
      (i: any) => i.userId === member.userId
    );

    // console.log(member, type);
    const params = {
      companyTeamId: this.team.id,
      userId: member.userId,
      role: type.toUpperCase(),
    };

    this.$apis
      .teamMembeRroleChangeHttp(params)
      .then((res: any) => {
        console.log("权限修改----", res);
        this.$message.success("修改成功");

        this.$set(this.memberList[index], "role", type);
      })
      .catch((err: any) => {
        // this.$message.error("修改失败");
        console.log("权限修改失败", err);
        // 改回原来的权限
      });
  }

  // 删除成员
  handleDelete(member: any) {
    console.log("删除了成员", member);

    const params = {
      companyTeamId: this.team.id,
      userId: member.userId,
    };

    this.$apis
      .deleteTeamMembeHttp(params)
      .then((res: any) => {
        console.log("删除成功", res);
        this.$message.success("删除成功");
        let index = this.memberList.findIndex(
          (i: any) => i.userId === member.userId
        );
        this.memberList.splice(index, 1);
        // 删除成员同步副本
        this.allMembers = JSON.parse(JSON.stringify(this.memberList));
      })
      .catch((err: any) => {
        // this.$message.error("修改失败");
        console.log("删除失败", err);
      });
  }

  handleCancel() {
    this.$emit("close");
  }

  // 点击确认
  handleOk() {
    console.log(this.modalType);
    if (this.modalType === "teamModal") {
      if (this.teamName === "") {
        this.$message.warning("团队名称不能为空");
      } else if (!this.memberList.every((i) => i.role)) {
        this.$message.warning("存在未分配权限的成员");
      } else {
        console.log("this.memberList", this.memberList);
        this.OkLoading = true;
        const params = {
          id: this.team.id ? this.team.id : undefined,
          companyTeamName: this.teamName,
        };
        console.log(params);
        if (this.type === "plus") {
          console.log("sjshjkshdkhdsjhfdsjkhfjfdsjkbnjdskbfdjks");

          this.$emit("plusOk", params);
        } else if (this.type === "edit") {
          this.$emit("editOk", params);
        } else {
          this.$emit("close");
        }
      }
    } else {
      let tempList = this.checkList.map((obj: { title: any; key: any }) => {
        return {
          nickname: obj.title,
          id: obj.key,
          userId: obj.key,
          role: "user",
        };
      });
      const merge: any[] = this.memberList
        .concat(this.selectMember)
        .concat(tempList);
      // 去重
      this.memberList = merge.reduce((total, value) => {
        if (total.findIndex((i: any) => i.userId === value.userId) === -1) {
          total.push(value);
        }
        return total;
      }, []);
      this.modalType = "teamModal";
      this.setTitle();
    }
  }

  // 获取最近成员
  queryRecentMember() {
    this.loading = true;
    this.$apis
      .companyTeamRecent({ companyId: this.companyId })
      .then((res: any) => {
        if (res.code === "200") {
          this.recentMemberList = res.data;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 获取联系人
  queryContactMember(value?: string | undefined) {
    this.loading = true;
    this.$apis
      .companyTeamContact()
      .then((res: any) => {
        if (res.code === 200) {
          console.log("树返回结果", res);
          let treeDataTemp = res.result;
          let processedTreeData = JSON.parse(JSON.stringify(treeDataTemp));
          processedTreeData = this.processTreeData(treeDataTemp);
          this.treeData = processedTreeData;
          console.log("联系人数据", this.treeData);
          this.tenantName = this.treeData[0].departName;
          this.loading = false;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  queryTeamDetail() {
    this.loading = true;
    this.$apis
      .companyTeamDetail({ id: this.team.id })
      .then((res: any) => {
        if (res.code === "200") {
          const result = res.data;
          console.log("🚀 ~ 联系人数据--初始:", result);
          this.teamName = result.teamName;
          // 按权限将成员进行排序
          const superList: any[] = [];
          const adminList: any[] = [];
          const userList: any[] = [];
          result.list.forEach((item: any) => {
            if (item.role === "super") {
              item.disabled = true;
              superList.push(item);
            } else if (item.role === "admin") {
              if (this.team.role !== "super") {
                item.disabled = true;
              }
              adminList.push(item);
            } else {
              userList.push(item);
            }
          });
          this.memberList = [...superList, ...adminList, ...userList];
          this.allMembers = JSON.parse(JSON.stringify(this.memberList));
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  //创建前钩子函数
  created(): void {
    this.setTitle();
    console.log(this.type);
    if (this.type === "plus") {
      this.memberList.push({
        isTeamMember: 1,
        // loginId: this.email || this.phone,
        email: this.email || this.phone,
        nickname: this.name,
        userId: this.userId,
        disabled: true,
        role: "super",
      });
    } else if (this.type === "view" || this.type === "edit") {
      this.queryTeamDetail();
    }
  }
}
