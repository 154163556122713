
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ClassComponent extends Vue {
  public listData: any[] = [];
  public spin: boolean = false;
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop()
  readonly team!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("team", { deep: true, immediate: true })
  change(value: any) {
    if (value.id) {
      this.spin = true;
      this.$apis
        .companyTeamDynamicPageList({
          isPage: 0,
          teamId: value.id,
        })
        .then((res: any) => {
          if (res.code === "200") {
            this.listData = res.data.list;
          }
        })
        .finally(() => {
          this.spin = false;
        });
    }
  }
  //ref 引用元素 Ref('ref')
  //@Ref()

  operate(
    operatorName: string,
    dynamicType: string,
    operateType: string,
    operatedObject?: any
  ) {
    if (dynamicType === "team") {
      switch (operateType) {
        case "INSERT":
          return `${operatorName}通过邀请链接加入团队`;
          break;
        case "UPDATE":
          return `${operatorName}编辑了团队信息`;
          break;
        case "DELETE":
          return `${operatorName}退出了团队`;
          break;
        default:
          break;
      }
    } else if (dynamicType === "doc") {

      switch (operateType) {
        case "INSERT":
          return `${operatorName}新增了文件《${operatedObject}》`;
          break;
        case "UPDATE":
          return `${operatorName}编辑了文件《${operatedObject}》`;
          break;
        case "DELETE":
          return `${operatorName}删除了文件《${operatedObject}》`;
          break;
        default:
          break;
      }
    }
  }

  handleClose() {
    this.$emit("close");
  }

  //创建前钩子函数
  created(): void {}
}
